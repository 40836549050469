<template>
  <p
    class="infobox"
    :class="{ 'infobox-error': type === 'error',
              'infobox-success': type === 'success',
              'infobox-no-margin': removeMargins }"
  >
    <slot />
  </p>
</template>

<script>
export default {
  name: 'BaseInfobox',
  props: {
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['error', 'success'].includes(value)
      }
    },
    removeMargins: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.infobox {
  color: #333;
  border-width: 2px;
  border-style: solid;
  border-radius: 0.6rem;
  padding: 0.6rem;
  margin: 2.5rem 0 0;
}

.infobox-error {
  border-color: #ff8686;
  background-color: #ffcbcb;
}

.infobox-success {
  border-color: #b1ffa0;
  background-color: #e4ffde;
}

.infobox-no-margin {
  margin: 0;
}
</style>
